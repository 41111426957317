import { IProject } from "@/components/Projects/type";
import { filter } from "lodash";

import { useCallback } from "react";

export const getCurrentTimestamp = (): string => {
  return new Date().toISOString();
};

export function filterDeletedObjects(objects: any[]): any[] {
  return objects.filter((obj) => !obj._deleted);
}

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: { path: any; type: any; message: any }) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

export function filterProjectsByName(projects: IProject[]) {
  if (projects) {
    return projects.filter((project) => {
      const name = project.name;
      return name != null && name.trim() !== "";
    });
  } else {
    return projects;
  }
}

export function filterCustomizationsByName(array: Array<any>, name: string) {
  return filter(array, (obj) => obj.id.includes(name))[0];
}

export function filterCustomizationsById(array: Array<any>, id: string) {
  return filter(array, (obj) => obj.id === id)[0];
}

export function isEmpty(obj: any): boolean {
  if (obj === undefined || obj === null) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  }

  return false;
}

export function camelCaseToWords(camelCaseString: string) {
  let result = camelCaseString.charAt(0).toLowerCase();
  const words = camelCaseString.slice(1).split(/(?=[A-Z])/);
  result += words.map((word) => word.toLowerCase()).join(" ");

  return result;
}

export function isDefined(obj: any, a: string, b: string): boolean {
  try {
    return obj?.[a]?.[b] !== undefined;
  } catch (error) {
    return false;
  }
}

export function includesInName(customizationsData: Array<any>, customizations: Array<string>, hasInName: string): any {
  return customizationsData.filter((obj) => customizations.includes(obj.id) && obj.name.includes(hasInName));
}
