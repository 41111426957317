import React, { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import "./toggleSelector.css";

interface Option {
  value: string;
  label: string;
}

type ToggleType = "Primary" | "Secondary";

const toggleTypes = {
  Primary: "card-container",
  Secondary: "header-card-container",
};

interface ToggleComponentProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  toggleType?: ToggleType;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({ options, value, onChange, toggleType = "Primary" }) => {
  const [currentSelection, setCurrentSelection] = useState(value);

  const items: TabsProps["items"] = options.map((option) => ({
    key: option.value,
    label: option.label,
  }));

  return (
    <div className={toggleTypes[toggleType]}>
      <Tabs
        type="card"
        activeKey={currentSelection}
        items={items}
        onChange={(v) => {
          onChange(v);
          setCurrentSelection(v);
        }}
      />
    </div>
  );
};

export default ToggleComponent;
