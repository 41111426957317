import type {} from "react-select/base";
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'myCustomProp' to it.

import { White_color } from "@/theme/antTheme";
import ReactSelect, { CSSObjectWithLabel, GroupBase, Props } from "react-select";

declare module "react-select/base" {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    containerStyle?: CSSObjectWithLabel;
    menuStyle?: CSSObjectWithLabel;
    menuListStyle?: CSSObjectWithLabel;
  }
}

const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group>,
) => {
  return (
    <ReactSelect
      {...props}
      styles={{
        control: (base: CSSObjectWithLabel) => ({
          ...base,
          minWidth: "300px",
          backgroundColor: White_color,
          minHeight: "48px",
          alignContent: "center",
          border: "1px solid rgba(11, 18, 21, 0.12)",
          paddingRight: "12px",
          borderRadius: "12px",
          boxShadow: "none",
          ...props.containerStyle,
        }),
        indicatorSeparator: () => ({ display: "none" }),
        dropdownIndicator: (style: CSSObjectWithLabel) => ({ ...style, color: "#0B1215" }),
        menu: (style: CSSObjectWithLabel) => ({ ...style, ...props.menuStyle }),
        menuList: (style: CSSObjectWithLabel) => ({ ...style, ...props.menuListStyle }),
      }}
    />
  );
};

export default Select;
