"use client";

import { Companies, Customizations, Users } from "@/models";
import { isEmpty, startCase } from "lodash";
import { useEffect, useState } from "react";
import { Button, Col, Input, Row, Spin, Typography } from "antd";
import Image from "next/image";
import FliterIcon from "../../../public/icons/filter.svg";
import { SearchOutlined } from "@ant-design/icons";
import ToggleSelector from "@/components/ToggleSelector/ToggleSelector";
import Select from "@/components/Select";
import { useParamSearch } from "@/hooks/useParamSearch";
import { defaultStatusFilter } from ".";

const FilterContainer = ({ children }) => {
  return (
    <Col xs={{ flex: "100%" }} sm={{ flex: "50%" }} md={{ flex: "40%" }} lg={{ flex: "20%" }} xl={{ flex: "10%" }}>
      {children}
    </Col>
  );
};

export function RenderFilters({
  company,
  usersPromise,
  availableSubcontractorsPromise,
  setProjectTypeFilter,
  projectTypeFilter,
  setSubcontractorFilter,
  subcontractorFilter,
  setStatusFilter,
  statusFilter,
  ownersFilter,
  setOwnersFilter,
  assigneesFilter,
  setAssigneesFilter,
  creatorsFilter,
  setCreatorsFilter,
  customizations,
  toggleOptions,
  tab,
  setTab,
}: {
  availableSubcontractorsPromise: Promise<{ companies: Companies[] }>;
  usersPromise: Promise<{
    users: Users[];
    count: number;
  }>;
  setOwnersFilter: (users: string[]) => void;
  setAssigneesFilter: (users: string[]) => void;
  setCreatorsFilter: (users: string[]) => void;
  setSubcontractorFilter: (subcontractors: string[]) => void;
  setStatusFilter: (statuses: string[]) => void;
  setProjectTypeFilter: (statuses: string[]) => void;
  statusFilter: string[];
  ownersFilter: string[];
  assigneesFilter: string[];
  creatorsFilter: string[];
  projectTypeFilter: string[];
  subcontractorFilter: string[];
  customizations: Customizations[];
  company: Companies;
  toggleOptions: { value: string; label: string }[];
  tab: string;
  setTab: (tab: string) => void;
}) {
  const haveFilters =
    JSON.stringify(statusFilter) !== JSON.stringify(defaultStatusFilter) ||
    ownersFilter.length ||
    assigneesFilter.length ||
    creatorsFilter.length ||
    projectTypeFilter.length ||
    subcontractorFilter.length;
  const [availableSubcontractors, setAvailableSubcontractors] = useState<Companies[]>([]);
  const [users, setuser] = useState<Users[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(!!haveFilters);
  const { localSearch, setLocalSearch } = useParamSearch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const subResponse = await availableSubcontractorsPromise;
      setAvailableSubcontractors(subResponse.companies);

      const { users: usersResponse } = await usersPromise;
      setuser(usersResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "completed", label: "Completed" },
    { value: "archived", label: "Archived" },
  ];

  const getSubcontractorNameById = (id: string) => {
    const subcontractor = availableSubcontractors.find((sub) => sub.id === id);
    return subcontractor?.name || "";
  };

  const getUserNameById = (id: string) => {
    const user = users.find((u) => u.id === id);

    return user ? `${user.firstName || ""} ${user.lastName || ""} (${user.email})` : "";
  };

  const getCustomizationNameById = (id: string) => {
    const customization = customizations.find((c) => c.id === id);
    return customization?.name || "";
  };

  const getUserSelectOptions = () => {
    const currentCompanyGroup = {
      label: company.name,
      options: users
        .filter((user) => user.companyId === company.id)
        .map((user) => ({ value: user.id, label: `${user.firstName || ""} ${user.lastName || ""} (${user.email})` })),
    };

    const otherCompanyGroups = availableSubcontractors.map((sub) => {
      return {
        label: sub.name,
        options: users
          .filter((user) => user.companyId === sub.id)
          .map((user) => ({ value: user.id, label: `${user.firstName || ""} ${user.lastName || ""} (${user.email})` })),
      };
    });

    return [currentCompanyGroup, ...otherCompanyGroups];
  };

  const customizationOptions = customizations.map((customization) => ({
    value: customization.id,
    label: startCase(customization.name!),
  }));

  const handleFiltersPressing = () => {
    setShowFilters((prevOpen) => !prevOpen);
  };

  return (
    <Col>
      <Row
        style={{
          marginBottom: "10px",
          marginTop: "10px",
          gap: 10,
        }}
      >
        <Col>
          <Spin spinning={loading}>
            <ToggleSelector
              options={toggleOptions}
              value={tab}
              onChange={(v) => {
                setTab(v);
              }}
            />
          </Spin>
        </Col>
        <Col flex={"auto"}>
          <Row
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Col style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
              <Input
                style={{ borderRadius: "100px", width: "600px", height: "48px" }}
                size="large"
                value={localSearch}
                placeholder="Search for a project"
                onChange={(event) => {
                  const value = event?.target.value;
                  setLocalSearch(value);
                }}
                prefix={<SearchOutlined />}
              />
              <Button
                style={{ height: "48px", width: "48px", minWidth: "48px", borderRadius: "12px" }}
                icon={<Image src={FliterIcon} alt="Fliter Icon" />}
                onClick={handleFiltersPressing}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col
        style={{ minHeight: "0px" }}
        className={`transition-all max-h-0 ease-in-out duration-700 ${showFilters ? "max-h-screen" : "overflow-hidden"}`}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <FilterContainer>
            <Typography.Text>Project Status</Typography.Text>
            <Select
              value={statusFilter
                .filter((status) => status !== "all")
                .map((status) => ({ value: status, label: startCase(status) }))}
              placeholder="Select statuses"
              isMulti
              options={statusOptions}
              onChange={(statuses) => {
                const newStatuses = statuses?.map((s) => s.value) || [];
                setStatusFilter(isEmpty(newStatuses) ? ["all"] : newStatuses);
              }}
            />
          </FilterContainer>
          <FilterContainer>
            <Typography.Text>Project Type</Typography.Text>
            <Select
              value={projectTypeFilter.map((customizationId) => ({
                value: customizationId,
                label: getCustomizationNameById(customizationId),
              }))}
              placeholder="Select type"
              isMulti
              options={customizationOptions}
              onChange={(statuses) => {
                setProjectTypeFilter(statuses?.map((s) => s.value));
              }}
            />
          </FilterContainer>

          {availableSubcontractors.length > 0 ? (
            <FilterContainer>
              <Typography.Text>Subcontractor</Typography.Text>
              <Select
                value={subcontractorFilter.map((subCon) => ({
                  value: subCon,
                  label: getSubcontractorNameById(subCon),
                }))}
                placeholder="Select subcontractors"
                isMulti
                options={availableSubcontractors.map((subCon) => ({
                  value: subCon.id,
                  label: subCon.name || "",
                }))}
                onChange={(subs) => {
                  setSubcontractorFilter(subs?.map((s) => s.value));
                }}
              />
            </FilterContainer>
          ) : null}
          <FilterContainer>
            <Typography.Text>Project Owner</Typography.Text>
            <Select
              value={ownersFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
              placeholder="Select users"
              isMulti
              //@ts-ignore
              options={getUserSelectOptions()}
              onChange={(userIds) => {
                setOwnersFilter(userIds?.map((userId) => userId.value));
              }}
            />
          </FilterContainer>
          <FilterContainer>
            <Typography.Text>Assigned User</Typography.Text>
            <Select
              value={assigneesFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
              placeholder="Select users"
              isMulti
              //@ts-ignore
              options={getUserSelectOptions()}
              onChange={(userIds) => {
                setAssigneesFilter(userIds?.map((userId) => userId.value));
              }}
            />
          </FilterContainer>
          <FilterContainer>
            <Typography.Text>Project Creator</Typography.Text>
            <Select
              value={creatorsFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
              placeholder="Select users"
              isMulti
              //@ts-ignore
              options={getUserSelectOptions()}
              onChange={(userIds) => {
                setCreatorsFilter(userIds?.map((userId) => userId.value));
              }}
            />
          </FilterContainer>
        </Row>
      </Col>
    </Col>
  );
}

export default RenderFilters;
