import { Col, Grid } from "antd";

const { useBreakpoint } = Grid;

const PageLayout = ({ header, children }) => {
  const screens = useBreakpoint();
  return (
    <Col>
      <div
        style={{
          paddingTop: screens.xs ? "16px" : "26px",
          paddingBottom: "0",
          paddingLeft: screens.xs ? "10px" : "20px",
          paddingRight: screens.xs ? "10px" : "20px",
        }}
      >
        {header}
      </div>
      <Col
        style={{
          backgroundColor: "#F4F5F7",
          borderRadius: "24px",
          minHeight: "80vh",
          paddingLeft: screens.xs ? "10px" : "20px",
          paddingRight: screens.xs ? "10px" : "20px",
          paddingTop: "16px",
        }}
      >
        {children}
      </Col>
    </Col>
  );
};

export default PageLayout;
