import { useQueryState } from "next-usequerystate";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";

export const useParamSearch = () => {
  const searchParams = useSearchParams();
  const [search, setSearch] = useQueryState("search", { defaultValue: searchParams.get("search") ?? "" });
  const [localSearch, setLocalSearch] = useState(search ?? "");
  const debouncedSearch = useDebounce(localSearch, 400);

  useEffect(() => {
    return () => setLocalSearch("");
  }, []);

  useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch]);

  return { searchParams, localSearch, setLocalSearch };
};
